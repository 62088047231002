import classNames from 'classnames';
import Head from 'next/head';
import React from 'react';
import AdSense from 'react-adsense';
import { connect } from 'react-redux';
import i18n from '../../locales/i18n';
import Helper from '../../modules/Helper';
import MobileOnly from '../Common/Responsive/MobileOnly';
import VertTitle from '../Common/VertTitle';
import NewsLetterForm from '../Flight/NewsLetterForm';
import SloganContainer from '../Flight/SloganContainer';
import Support from '../Flight/Support';
import MainLayout from '../Layouts/MainLayout';
import Companies from './Companies';
import FilterForm from './FilterForm';

type HotelsProps = {
	dynamic?: boolean;
	useHeadline?: boolean;
	place?: string | string[];
	brandsDescription?: string;
	hotelName?: string;
	page?: any;
	query?: any;
	lng: string;
};
type HotelsState = {
	vertTitle: string;
	brandsDescription: string;
	newsletterSlogan: string;
	title: string;
	description: string;
	metaDescription: string;
	metaTitle: string;
	url: string;
};
class Hotels extends React.Component<HotelsProps, HotelsState> {
	constructor(props: HotelsProps) {
		super(props);
		this.state = {
			vertTitle: 'mainArea.hotels.title',
			brandsDescription: 'main.hotels.brandsDescription',
			newsletterSlogan: i18n.t('main.hotels.newsletterSlogan'),
			title: 'metadata.hotels.title',
			description: 'metadata.hotels.description',
			metaTitle: props.page?.title || 'Hotels lowest prices',
			metaDescription:
				props.page?.metaDescription ||
				"We search hotels and travel booking sites across the web to find the world's lowest prices",
			url: props.page
				? `https://travelpricedrops.com/hotels/${props.page.slug}${props.page?.lng ? `?lng=${props.page.lng}` : ''}`
				: 'https://travelpricedrops.com/hotels',
		};
	}

	componentDidMount() {
		if (this.props.dynamic && this.props.place) {
			const place = this.props.place as string;
			const placeName = Helper.convertSlugToName(place);
			this.setState({
				vertTitle: `Compare ${placeName} Hotels`,
				brandsDescription: `We're comparing the best ${placeName} hotel deals from over 300 travel sites in one search.`,
				newsletterSlogan: `Be the first to know when <br/><span class="color-yellow">${placeName} hotel room rates go down</span>`,
				title: `Compare ${placeName} Hotels`,
				description: `If you are looking for low rates on hotels in ${placeName}, look no further.`,
			});
		}
		if (this.props.brandsDescription) {
			this.setState({
				brandsDescription: `Search Hotel Booking Sites, ${this.props.brandsDescription}`,
			});
		}
		if (this.props.hotelName) {
			this.setState({
				vertTitle: `Compare ${this.props.hotelName}`,
			});
		}
		if (this.props.page?.headingOne && this.props.page?.headingTwo) {
			this.setState({
				vertTitle: this.props.page.headingOne,
				brandsDescription: this.props.page.headingTwo,
			});
		}

		const texts = this.props.query?.texts;
		if (texts) {
			this.setState({
				metaDescription: texts.metaDescription,
				metaTitle: texts.metaTitle,
				vertTitle: texts.h1,
				brandsDescription: texts.h2,
				newsletterSlogan: texts.newsletter,
			});
		}
	}

	renderAdSense = () => {
		if (typeof window != 'undefined' && Helper.urlContainsSearchParams()) {
			if (process.env.NEXT_PUBLIC_SHOW_PRODUCTION_SCRIPTS === 'true' && Helper.checkQueryStringKeyValue('ad', '1')) {
				return (
					<>
						<AdSense.Google
							client="ca-pub-2732183997877262"
							slot="7698545245"
							style={{ display: 'block' }}
							format="auto"
							responsive="true"
						/>
						<br />
					</>
				);
			}
		}
		return '';
	};

	render() {
		const isRtl = Helper.isRtlLanguage(this.props.lng);
		const isArabic = Helper.isArabicLanguage(this.props.lng);

		return (
			<MainLayout>
				<Head>
					<script
						type="application/ld+json"
						dangerouslySetInnerHTML={{
							__html: `
										{
												"@context": "http://schema.org",
												"@type": "Service",
												"name": "${this.state.metaTitle}",
												"description" : "${this.state.metaDescription}",
												"url" :"${this.state.url}",
												"image" : "https://travelpricedrops.com/images/block-background-hotels-1-copy-3.png?v1.0"
										}`,
						}}
					/>
				</Head>
				<div
					id="main-area"
					className={classNames('hotels-container', {
						rtl: isRtl,
						arabic: isArabic,
					})}
				>
					<div className="background-opacity" />
					<div className="container">
						<div className="row justify-content-center align-items-center">
							<div className="hotels-filter-form col-12">
								<div className="row">
									<div id="filter-form-wrapper" className="col-md">
										<VertTitle useHeadline={this.props.useHeadline} i18nKey={this.state.vertTitle} title={this.props.page?.h1} />
										<FilterForm />
									</div>
								</div>
							</div>
						</div>
						<div className="row justify-content-between align-items-center">
							<div className="col-12 col-md-8 text-center m-auto">
								<Companies page={this.props.page} useHeadline={this.props.useHeadline} description={this.state.brandsDescription} />
							</div>
						</div>
						<div className="clearfix" />
						<div className="d-xl-block d-none">
							<br />
							<br />
							<br />
							<br />
							<br />
							<br />
							<br />
						</div>
					</div>
					<div className="curve-mask">
						<div className="curve-up" id="curve-3"></div>
					</div>
				</div>
				<SloganContainer vert="Hotels" sloganKey="main.hotels.verticalSlogan" img="/images/world-map.png?v1.0">
					{this.renderAdSense()}
					<MobileOnly>
						<Support />
					</MobileOnly>
				</SloganContainer>
				<NewsLetterForm>
					<h5
						dangerouslySetInnerHTML={{
							__html: this.state.newsletterSlogan,
						}}
					/>
				</NewsLetterForm>
			</MainLayout>
		);
	}
}

// @ts-ignore
const mapStateToProps = (state) => ({
	lng: state.base.lng,
});

export default connect(mapStateToProps, {})(Hotels);
