import FadeTransition from 'app/components/Common/FadeTransition';
import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { setPriceAlertEmail } from '../../../actions/baseActions';
import ApiInterface from '../../modules/ApiInterface';
import Helper from '../../modules/Helper';
import Trans from '../Common/Trans';

type NewsLetterFormProps = {
	enabledVert: string;
	lng: string;
	children: ReactNode;
	setPriceAlertEmail: (email: string) => void;
};
type NewsLetterFormState = {
	email: string;
	disabled: boolean;
	isInvalidEmail: boolean;
};
class NewsLetterForm extends React.Component<NewsLetterFormProps, NewsLetterFormState> {
	constructor(props: NewsLetterFormProps) {
		super(props);
		this.state = {
			email: '',
			disabled: false,
			isInvalidEmail: false,
		};
	}

	onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({ email: e.target.value });
	};
	onSubmitForm = (e: React.FormEvent) => {
		if (this.state.disabled) {
			return;
		}
		e.preventDefault();
		const email = this.state.email;

		if (!Helper.isValidEmail(email) && email) {
			console.log('invalid email');

			this.setState({
				isInvalidEmail: true,
			});
			return;
		}

		this.setState({
			isInvalidEmail: false,
		});
		const params = new URLSearchParams();
		params.append('user-id', email);
		params.append('lng', this.props.lng);
		this.props.setPriceAlertEmail(email);
		ApiInterface.instance
			.newsletterSubscription(this.props.enabledVert, params)
			.then(() => {
				this.setState({
					email: '',
					disabled: true,
				});
			})
			.catch(() => {});
	};

	render() {
		const { email, disabled } = this.state;
		const isRtl = Helper.isRtlLanguage(this.props.lng);

		return (
			<div id="newsletter-form">
				<div className="container">
					<div
						className={classNames('row align-items-center justify-content-center', {
							'flex-row-reverse': isRtl,
						})}
					>
						<div className="col-lg-5 text-center mb-5 mt-5 ">
							<h4>
								<Trans i18nKey="newsletter.title" />
							</h4>
							{this.props.children}
						</div>
						<div className="col-lg-7 text-center">
							<form
								noValidate
								onSubmit={this.onSubmitForm}
								className={classNames('row justify-content-center', {
									'flex-row-reverse': isRtl,
								})}
								action="#"
							>
								<div className="col-lg-9 col-md-7 col-12">
									<div className="form-group flex-1 mb-0 position-relative">
										<FadeTransition timeout={1003} in={this.state.isInvalidEmail}>
											<div
												className="tooltip-error"
												style={{ transform: 'translateY(50%)' }}
												data-testid="newsletter-email-error"
											>
												{Helper.trans('validation.priceAlert.email')}
											</div>
										</FadeTransition>
										<input
											value={email}
											onChange={this.onChange}
											disabled={disabled}
											placeholder={
												disabled
													? Helper.trans('newsletter.successMessage')
													: Helper.trans('newsletter.inputPlaceholder')
											}
											type="email"
											className="form-control"
											id="email"
											style={{ width: '100%' }}
										/>
									</div>
								</div>
								<div className="col-lg-3 col-md-5 col-12 mt-4 mt-md-0">
									<button
										disabled={disabled || !email}
										style={{ width: '100%' }}
										type="submit"
										className="btn btn-warning btn-yellow"
									>
										<Trans i18nKey="keywords.submit" />
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
// @ts-ignore
const mapStateToProps = (state) => ({
	enabledVert: state.base.enabledVert,
	lng: state.base.lng,
});
export default React.memo(connect(mapStateToProps, { setPriceAlertEmail })(NewsLetterForm));
