import dynamic from 'next/dynamic';
import { FC } from 'react';
import Trans from '../Common/Trans';

const companies = [
	'/images/companies/booking.png?v1.1',
	'/images/companies/expedia.png?v1.1',
	'/images/companies/hotels-com.png?v1.1',
	'/images/companies/priceline.png?v1.1',
	'/images/companies/hotwire.png?v1.1',
	'/images/companies/kayak.png?v1.1',
	'/images/companies/trivago.png?v1.1',
	'/images/companies/travelocity.png?v1.1',
];
type CompaniesProps = {
	useHeadline?: boolean;
	description?: string;
	page?: any;
};

const CompanyImage = dynamic(() => import('../Common/CompanyImage'), { ssr: true });

const Companies: FC<CompaniesProps> = (props) => {
	const { description, useHeadline, page } = props;

	return (
		<div className="text-center" id="companies">
			{useHeadline ? (
				<h2 className="description mb-2">
					{page?.h2 || (<Trans i18nKey={description || 'main.hotels.brandsDescription'} />)}
				</h2>
			) : (
				<div className="description mb-2">
					{page?.h2 || (<Trans i18nKey={description || 'main.hotels.brandsDescription'} />)}
				</div>
			)}
			<ul className="companies">
				{companies.map((companyImage, key) => {
					return (
						<li key={key}>
							<CompanyImage src={companyImage} />
						</li>
					);
				})}
			</ul>
		</div>
	);
};
export default Companies;
